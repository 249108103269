import { Chip, Box, Typography, Grid, useMediaQuery } from "@mui/material";
import React from "react";

const WhatWeDo = () => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  return (
    <Box pt={15} id="WhatWeDo">
      <Box mx="auto" width="fit-content">
        <Chip
          label="What We Do?"
          color="primary"
          style={{
            backgroundColor: "#EDEEF9",
            color: "#505DC6",
            padding: "1.3rem 0.5rem",
            borderRadius: "2rem"
          }}
        />
      </Box>
      <Box mt={4} mb={10} style={{ textAlign: "center" }}>
        <Typography variant="h4" fontWeight={700}>
          We love what we do.
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" mt={2}>
          We look beyond beautiful design and latest technology, <br /> we focus on the success of
          your product.
        </Typography>
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        flexDirection={isSmallScreen ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight={700}>
            Blockchain Applications
          </Typography>
          <br />
          <Typography variant="subtitle1" color="textSecondary">
            We have a skilled and dedicated team of Blockchain Developers for Smart Contracts and
            Solidity, designing, developing and deploying your projects on any chain in a highly
            secure fashion. We also offer blockchain consulting services to companies and
            businesses.
          </Typography>

          <Box display="flex">
            <ul>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  NFT Collections
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  NFT Marketplace
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  ICO
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  DAO
                </Typography>
              </li>
            </ul>
            <ul style={{ paddingLeft: "16px" }}>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  Swap
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  Launchpad
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" component="span" fontWeight={700}>
                  Locker
                </Typography>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src="/assets/img/block.png" alt="what we do" />
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems="center"
        my={isSmallScreen ? 8 : 0}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={5}>
          <img src="/assets/img/web.png" alt="what we do" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight={700}>
            Website Applications
          </Typography>
          <br />
          <Typography variant="subtitle1" color="textSecondary">
            We have worked on a variety of websites with our partners to craft elegant and flawless
            web experiences for users. We build responsive website applications that work seamlessly
            across devices.
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle1" component="span" fontWeight={700}>
                Backend:
              </Typography>
              &nbsp; Node js, Django, Ruby on Rails (RoR)
            </li>
            <li>
              <Typography variant="subtitle1" component="span" fontWeight={700}>
                Frontend:
              </Typography>
              &nbsp; React, Angular, Flutter
            </li>
            {/* <li>
              We love
              <Typography component="span" variant="subtitle1" fontWeight={700}>
                &nbsp; Java Script &nbsp;
              </Typography>
              and experimenting in house
            </li> */}
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        flexDirection={isSmallScreen ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight={700}>
            Mobile Application
          </Typography>
          <br />
          <Typography variant="subtitle1" color="textSecondary">
            We can design and develop efficient Android and IOS mobile applications. We can deliver
            your product by using native or cross-platform technologies
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle1" component="span" fontWeight={700}>
                Kotlin
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" component="span" fontWeight={700}>
                Flutter
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" component="span" fontWeight={700}>
                React Native
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src="/assets/img/mobile.png" alt="what we do" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatWeDo;
