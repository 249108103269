import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const Intro = () => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  return (
    <Grid
      mt={10}
      container
      justifyContent={isSmallScreen ? "center" : "space-between"}
      style={{ flexDirection: isSmallScreen ? "column-reverse" : "row" }}
      alignItems="center"
      rowSpacing={isSmallScreen ? 8 : 0}
    >
      <Grid md={6} xs={12} item>
        <Typography fontFamily="sora" fontWeight={500} variant="h3">
          Best way to <br />
          create a <br /> <span style={{ color: "#505DC6" }}>Creative Product</span>.
        </Typography>
        <br />
        <br />
        <Typography color="textSecondary" variant="subtitle1">
          Leading digital agency with extensive design and development expertise.
        </Typography>
        <br />
        <br />
        <Box display="flex">
          <Button size="large" variant="contained" href="#WhatWeDo">
            <Typography variant="subtitle1" px={2}>
              What We Do?
            </Typography>
          </Button>
          <Button style={{ marginLeft: "1rem" }} variant="outlined" href="#ContactUs">
            <Typography variant="subtitle1" px={2}>
              Contact Us
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid md={5} xs={12} item>
        <img src="/assets/img/sec1-img.svg" alt="intro" />
      </Grid>
    </Grid>
  );
};

export default Intro;
