import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const Footer = () => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  return (
    <Box mt={10}>
      <Box pt={10} pb={3} width="100vw" style={{ backgroundColor: "#0C1D37", color: "white" }}>
        <Container>
          <Grid
            container
            justifyContent="space-between"
            textAlign={isSmallScreen ? "center" : "left"}
          >
            <Grid item xs={12} md={6}>
              <Box
                mx="auto"
                display="flex"
                justifyContent={isSmallScreen ? "center" : "flex-start"}
                alignItems="center"
              >
                <img style={{ width: "30px" }} src="/assets/img/logo.svg" alt="logo" />
                <Typography ml={1} variant="h6" fontFamily="Sora" fontWeight={500}>
                  Tethys
                </Typography>
              </Box>
              <br />
              <Typography variant="subtitle1" style={{ color: "#9DA3AB" }}>
                We have built software of all shapes and sizes for over fifty companies. Whether
                you’re a start-up or a scale-up, we’re here to help you.
              </Typography>
            </Grid>
            <Grid
              textAlign={isSmallScreen ? "center" : "right"}
              item
              xs={12}
              md={6}
              my={isSmallScreen ? 5 : 0}
            >
              <Typography variant="subtitle2">Tethys Global Kft</Typography> <br />
              <Typography variant="subtitle2">1121 Budapest, Zugligeti út 6</Typography> <br />
              <Typography variant="subtitle2">
                Tel:
                <a href="tel:+36706372878"> +36706372878</a>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={isSmallScreen ? {} : { marginTop: "20px", borderTop: "1px solid #25344B" }}
            pt={3}
            textAlign={isSmallScreen ? "center" : "left"}
            flexDirection={isSmallScreen ? "column-reverse" : "row"}
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Typography variant="caption" style={{ color: "#9DA3AB" }}>
                Copyright © 2021-2022 Tethys. All rights reserved.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className={isSmallScreen ? "socials-responsive" : "socials"}
              >
                <a href="#" target="_blank">
                  <img src="/assets/img/discord.svg" alt="discord" />
                </a>
                <a href="#" target="_blank">
                  <img src="/assets/img/Telegram.svg" alt="telegram" />
                </a>
                <a href="#" target="_blank">
                  <img src="/assets/img/whatsapp.svg" alt="whatsapp" />
                </a>
                <a href="#" target="_blank">
                  <img src="/assets/img/linkedin.svg" alt="linkedin" />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
