import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#505DC6"
    },
    secondary: {
      main: "#19857b"
    },
    error: {
      main: red.A400
    },
    text: {
      primary: "#0C1D37",
      secondary: "#3B4555"
    }
  },
  typography: {
    h5: {
      fontWeight: 600
    },
    fontFamily: "Poppins"
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2rem",
          textTransform: "none",
          fontSize: ".7rem",
          padding: "0.6rem 1.4rem",
          boxShadow: "none !important"
        },
        outlined: {
          borderWidth: "3px !important"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #E6E6E6",
          padding: "0.6rem 1.4rem",
          fontSize: ".8rem"
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1200,
      xl: 1440
    }
  }
});

export default theme;
