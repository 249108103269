import { Menu } from "@mui/icons-material";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import DropDownNavBar from "./DropDownNavBar";

const Navbar = () => {
  const isLargeScreen = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <img src="/assets/img/logo.svg" alt="logo" />
          <Typography ml={1} variant="h4" fontFamily="Sora" fontWeight={500}>
            Tethys
          </Typography>
        </Box>
        {isLargeScreen ? (
          <Grid container spacing={3} alignItems="center" style={{ width: "fit-content" }}>
            <Grid item>
              <Typography variant="subtitle1">
                <a href="#WhatWeDo">What We Do?</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <a href="#HowWeWork">How We Work?</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <a href="#SimilarWorks">Similar Works</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <a href="#FAQ">FAQ</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <a href="#ContactUs">Contact Us</a>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Menu
            onClick={() => {
              setDropdownIsOpen(!dropdownIsOpen);
            }}
            style={{ color: "#000", fontSize: "3rem", marginTop: "-0.5rem" }}
          />
        )}
      </Box>
      <DropDownNavBar
        isOpen={dropdownIsOpen}
        onClose={() => {
          setDropdownIsOpen(false);
        }}
      />
    </div>
  );
};

export default Navbar;
