import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const DropDownNavBar = ({ isOpen, onClose }: IProps) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      bgcolor="#00000050"
      width="100%"
      height="100%"
      zIndex={999}
      onClick={onClose}
      style={{
        transition: "all 0.5s",
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? "auto" : "none"
      }}
    >
      <Box
        height="fit-content"
        p={4}
        bgcolor="#FFFFFF"
        position="relative"
        borderRadius="0 0 1rem 1rem"
        top={isOpen ? "0" : "-100%"}
        style={{
          transition: "all 0.5s"
        }}
      >
        <CloseIcon onClick={onClose} style={{ float: "right", fontSize: "2rem" }} />
        <div style={{ clear: "both" }} />
        <Grid container direction="column" spacing={4} textAlign="center">
          <Grid item>
            <Typography variant="subtitle1">
              <a href="#WhatWeDo">What We Do?</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <a href="#HowWeWork">How We Work?</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <a href="#SimilarWorks">Similar Works</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <a href="#FAQ">FAQ</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <a href="#ContactUs">Contact Us</a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DropDownNavBar;
