import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import "./assets/scss/main.scss";
import "react-toastify/dist/ReactToastify.css";

import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
const container = document.getElementById("root");
const root = container && createRoot(container); // createRoot(container!) if you use TypeScript
root?.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
    <ToastContainer />
  </ThemeProvider>
);
