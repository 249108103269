import { Box, Chip, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const HowWeWork = () => {
  return (
    <Box id="HowWeWork" mt={10}>
      <Box py={8} width="100vw" style={{ backgroundColor: "#0C1D37", color: "white" }}>
        <Box mx="auto" width="fit-content">
          <Chip
            label="How We Work?"
            color="primary"
            style={{
              backgroundColor: "#273555",
              color: "#939AAA",
              padding: "1.3rem 0.5rem",
              borderRadius: "2rem"
            }}
          />
        </Box>
        <Typography mt={4} align="center" variant="h5" fontWeight={700}>
          Design Driven Process
        </Typography>
        <Typography mt={4} mb={8} align="center" variant="subtitle1" style={{ color: "#9DA3AB" }}>
          Your roadmap to capitalize <br /> on the blockchain opportunity.
        </Typography>
        <Container maxWidth="xl">
          <Grid className="howwework" justifyContent="center" container spacing={3}>
            <Grid item xs={12} md={5} lg={3}>
              <Paper style={{ height: "100%" }} className="howwework-item">
                <img
                  style={{ width: "40%" }}
                  src="/assets/img/howwework-img3.svg"
                  alt="howwework"
                />
                <Typography mt={3} variant="subtitle1" fontWeight={500}>
                  1. Requirement Gathering
                </Typography>
                <Typography mt={4} mb={7} variant="subtitle2" style={{ color: "#9DA3AB" }}>
                  We start by documenting all the features and details required for the project.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <Paper style={{ height: "100%" }} className="howwework-item">
                <img
                  style={{ width: "40%" }}
                  src="/assets/img/howwework-img2.svg"
                  alt="howwework"
                />
                <Typography mt={3} variant="subtitle1" fontWeight={500}>
                  2. System Design
                </Typography>
                <Typography mt={4} mb={7} variant="subtitle2" style={{ color: "#9DA3AB" }}>
                  Our technical team designs various aspects of your project, we will move forward
                  once you accept the design.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <Paper style={{ height: "100%" }} className="howwework-item">
                <img
                  style={{ width: "40%" }}
                  src="/assets/img/howwework-img4.svg"
                  alt="howwework"
                />
                <Typography mt={3} variant="subtitle1" fontWeight={500}>
                  3. Development
                </Typography>
                <Typography mt={4} mb={7} variant="subtitle2" style={{ color: "#9DA3AB" }}>
                  Our developers try to develop your project as quickly and efficiently as possible.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <Paper style={{ height: "100%" }} className="howwework-item">
                <img style={{ width: "40%" }} src="/assets/img/howwework-img.svg" alt="howwework" />
                <Typography mt={3} variant="subtitle1" fontWeight={500}>
                  4. Testing and Quality Assurance
                </Typography>
                <Typography mt={4} mb={7} variant="subtitle2" style={{ color: "#9DA3AB" }}>
                  After development, we run extensive tests on the program to make sure everything
                  is running as expected.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default HowWeWork;
