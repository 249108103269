import {
  Container,
  Box,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
  return (
    <Box id="FAQ" mt={10}>
      <Box py={10} width="100vw" style={{ backgroundColor: "#0C1D37", color: "white" }}>
        <Box mx="auto" width="fit-content">
          <Chip
            label="FAQ"
            color="primary"
            style={{
              backgroundColor: "#273555",
              color: "#939AAA",
              padding: "1.3rem 0.5rem",
              borderRadius: "2rem"
            }}
          />
        </Box>
        <Typography px={5} mt={4} align="center" variant="h5" fontWeight={700}>
          Frequently asked questions
        </Typography>
        <Typography
          px={3}
          mt={4}
          mb={8}
          align="center"
          variant="subtitle1"
          style={{ color: "#9DA3AB" }}
        >
          Looking for more info? <br />
          Here are some questions we're commonly asked.
        </Typography>
        <Container maxWidth="lg">
          <Accordion className="accordion" disableGutters style={{ margin: "1rem" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                What is the time and cost for a specific type of project?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: "#9DA3AB" }}>
                The time and cost for project development then completely depends on the designing &
                development process you choose (among other things).
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion" disableGutters style={{ margin: "1rem" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Do you provide custom development services?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: "#9DA3AB" }}>
                Absolutely! No matter the type of service you require, we can build your projects
                and meet your goals, as long as it is within our technical ability.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion" disableGutters style={{ margin: "1rem" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What chains can you deploy your projects on?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: "#9DA3AB" }}>
                We can deploy on any chain you desire, for both mainnet and testnet.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </Box>
  );
};

export default FAQ;
