import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Typography
} from "@mui/material";
import { AttachSquare, Call, DirectboxDefault, Document, Profile } from "iconsax-react";
import React, { useState } from "react";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");

  const [file, setFile] = useState<File | undefined>(undefined);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      console.log(files[0]);
      setFile(files[0]);
    }
  };
  const handleFormSublit = (e: any) => {
    e.preventDefault();
    if (!(title && email && description && phone)) {
      return toast.error("Please fill all the fields");
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("email", email);
    formData.append("description", description);
    formData.append("phone", phone);
    fetch("https://contact.bitium.agency/messages", {
      method: "POST",
      body: formData
    })
      .then((res) => {
        if (res.ok) {
          toast.success("Your message has been sent");
          setTitle("");
          setEmail("");
          setDescription("");
          setPhone("");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  return (
    <Box pt={10} id="ContactUs">
      <Box mx="auto" width="fit-content">
        <Chip
          label="Contact Us"
          color="primary"
          style={{
            backgroundColor: "#EDEEF9",
            color: "#505DC6",
            padding: "1.3rem 0.5rem",
            borderRadius: "2rem"
          }}
        />
      </Box>
      <Box mt={4} mb={10} style={{ textAlign: "center" }}>
        <Typography variant="h4" fontWeight={700}>
          Get In Touch
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" mt={3}>
          Please submit the form below, we promise to get back to you <br />
          within one business day.
        </Typography>
      </Box>
      <Container maxWidth="md">
        <form method="POST" onSubmit={handleFormSublit}>
          <Grid container rowSpacing={5} spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Input
                  id="input-with-icon-adornment"
                  placeholder="Ttile"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  disableUnderline
                  startAdornment={
                    <InputAdornment position="start">
                      <Document color="#000" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Input
                  id="input-with-icon-adornment"
                  placeholder="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  disableUnderline
                  multiline
                  startAdornment={
                    <InputAdornment position="start">
                      <Document color="#000" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Input
                  id="input-with-icon-adornment"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  disableUnderline
                  startAdornment={
                    <InputAdornment position="start">
                      <DirectboxDefault color="#000" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Input
                  id="input-with-icon-adornment"
                  placeholder="Your phone"
                  disableUnderline
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  startAdornment={
                    <InputAdornment position="start">
                      <Call color="#000" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="raised-button-file">
                <Box
                  width="100%"
                  py={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid #E6E6E6"
                  borderRadius={2}
                >
                  <AttachSquare style={{ color: "#505DC6", marginRight: "8px" }} />
                  {file ? file.name : "Attach your file"}
                </Box>
              </label>
            </Grid> */}
          </Grid>
          <Box mt={7} mx="auto" width="fit-content">
            <Button type="submit" variant="contained" color="primary">
              <Box px={6}>Submit</Box>
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default ContactUs;
