import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const OurWork = () => {
  return (
    <Box id="SimilarWorks" pt={15}>
      <Box mx="auto" width="fit-content">
        <Chip
          label="Similar Works"
          color="primary"
          style={{
            backgroundColor: "#EDEEF9",
            color: "#505DC6",
            padding: "1.3rem 0.5rem",
            borderRadius: "2rem"
          }}
        />
      </Box>
      <Box mt={4} mb={10} style={{ textAlign: "center" }}>
        <Typography variant="h4" fontWeight={700}>
          The Creative Agency You Need
        </Typography>
        <Typography color="secondary" variant="subtitle1" mt={2}>
          We can bring your ideas to life <br />
          We can design and develop products similar to these
        </Typography>
      </Box>
      <Grid container justifyContent="center" rowSpacing={10} spacing={5}>
        <Grid item xs={12} md={5} lg={4}>
          <a target="_blank" href="https://boredapeyachtclub.com/#/">
            <Box borderRadius={10} overflow="hidden">
              <img src="/assets/img/image3.png" alt="our work" />
            </Box>
            <br />
            <Chip
              color="primary"
              variant="outlined"
              style={{ fontWeight: 600 }}
              label="NFT Collection"
            />
            <Typography my={2} variant="h6" fontWeight={600}>
              BAYC
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Developing nft collections with features such as whitelist, anti-whale, ERC721A gas
              optimization and more.
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <a
            target="_blank"
            href="https://marketplace.axieinfinity.com/axie/?auctionTypes=Sale&game=origin"
          >
            <Box borderRadius={10} overflow="hidden">
              <img src="/assets/img/image5.png" alt="our work" />
            </Box>
            <br />
            <Chip
              color="primary"
              variant="outlined"
              style={{ fontWeight: 600 }}
              label="NFT Marketplace"
            />
            <Typography my={2} variant="h6" fontWeight={600}>
              Axie Infinity
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Creating marketplaces with different categories, filters, graphical maps, and
              statistics sections.
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <a target="_blank" href="https://app.olympusdao.finance/#/dashboard">
            <Box borderRadius={10} overflow="hidden">
              <img src="/assets/img/image1.png" alt="DAO" />
            </Box>{" "}
            <br />
            <Chip color="primary" variant="outlined" style={{ fontWeight: 600 }} label="DAO" />
            <Typography my={2} variant="h6" fontWeight={600}>
              Olympus DAO
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              DAO system with options such as staking, bonds, ICO and governance.
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <a target="_blank" href="https://pancakeswap.finance/swap">
            <Box borderRadius={10} overflow="hidden">
              <img src="/assets/img/image4.png" alt="our work" />
            </Box>
            <br />
            <Chip color="primary" variant="outlined" style={{ fontWeight: 600 }} label="SWAP" />
            <Typography my={2} variant="h6" fontWeight={600}>
              PancakeSwap
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Developing and deploying swaps, liquidity pools, tokens and IFOs.
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <a target="_blank" href="https://bscpad.com/">
            <Box borderRadius={10} overflow="hidden">
              <img src="/assets/img/image2.png" alt="our work" />
            </Box>
            <br />
            <Chip
              color="primary"
              variant="outlined"
              style={{ fontWeight: 600 }}
              label="Launchpad"
            />
            <Typography my={2} variant="h6" fontWeight={600}>
              BSCpad
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Creating launchpads to empower and raise crypto projects.
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <a target="_blank" href="https://app.unicrypt.network/dashboard">
            <Box borderRadius={10} overflow="hidden">
              <img src="/assets/img/image6.png" alt="our work" />
            </Box>{" "}
            <br />
            <Chip color="primary" variant="outlined" style={{ fontWeight: 600 }} label="Locker" />
            <Typography my={2} variant="h6" fontWeight={600}>
              Unicrypt
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Developing an all-in-one locker platform
            </Typography>
          </a>
        </Grid>
      </Grid>
      {/* <Box mt={9} mb={16} width="fit-content" mx="auto">
        <Button variant="contained" color="primary">
          <Typography px={4}>Show More</Typography>
        </Button>
      </Box> */}
    </Box>
  );
};

export default OurWork;
