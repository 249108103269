import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Navbar from "./components/Navbar";
import Intro from "./components/intro";
import WhatWeDo from "./components/WhatWeDo";
import HowWeWork from "./components/HowWeWork";
import OurWork from "./components/OurWork";
import FAQ from "./components/FAQ";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";

export default function App() {
  return (
    <Box sx={{ mt: 4 }}>
      <Container maxWidth="xl">
        <Navbar />
        <Intro />
        <WhatWeDo />
      </Container>
      <HowWeWork />
      <Container maxWidth="xl">
        <OurWork />
      </Container>
      <FAQ />
      <Container maxWidth="xl">
        <ContactUs />
      </Container>
      <Footer />
    </Box>
  );
}
